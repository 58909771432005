import "./Properties.scss";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { Col, Row, Tab, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Auth } from "aws-amplify";

import Sidebar from "../../components/SideBar";
import { ChevronRight } from "react-feather";
import Details from "./tabs/Details";
import Tabs from "../../components/Tabs";
import LockButton from "../../components/LockButton";
import DoorConfirmation from "./components/DoorConfirmation";
import System from "./tabs/System";
import CameraFeed from "./tabs/CameraFeed";
import Leads from "./tabs/Leads";
import { useGeneral } from "../../context/GeneralContext";
import { useProperty } from "../../context/PropertyContext";
import UseHttp from "../../hooks/UseHttp";
import LoaderRing from "../../components/LoaderRing";
import removeUndefinedWord from "../../helper/updateString";
import { MdRefresh } from "react-icons/md";
import Users from "./tabs/Users";
import useUser from "../../hooks/useCurrentUser";
import DoorStatus from "./components/DoorStatus";

const PropertiesList = () => {
  const [currentLock, setCurrentLock] = useState(undefined); // used to track if the property has a lock
  const [lockStatus, setLockStatus] = useState(false); // used to send a command to lock or unlock
  const [doorStatus, setDoorStatus] = useState("");
  console.log(doorStatus)
  const [lockUncalibrated, setLockUncalibrated] = useState(false); // if the lock is uncalibrated
  const [showLockConfirmation, setShowLockConfirmation] = useState(false); // used to control lock modal display
  const [loadingLockState, setLoadingLockState] = useState(false); // used when locking/unlocking the lock.
  const [isRefreshing, setIsRefreshing] = useState(false); // used when fetching lock status
  const [lockError, setLockError] = useState("");

  const params = useParams();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("details");
  const { setAlertModal } = useGeneral();
  const { userData } = useUser();
  const {
    property,
    setProperty,
    setCameras,
    setCameraUrl,
    setShowToast,
    leadsCount,
  } = useProperty();
  const {
    isLoading: propertyLoading,
    error: propertyError,
    requestData: handleGetProperty,
  } = UseHttp();
  const navigate = useNavigate();

  useEffect(() => {
    // Checking if query params including activeTab
    const params = new URLSearchParams(location.search);
    const activeTabParam = params.get("activeTab");

    if (activeTabParam) {
      setActiveTab(activeTabParam);
    }
  }, [location.search]);

  // Run useEffect once to get Property details.
  useEffect(() => {
    const id = params.id; // Get the property id from the URL in the browser

    if (id) {
      getPropertyByID(id);
      if (!window.location.href.includes("localhost")) {
        ReactGA.initialize(`${process.env.REACT_APP_GA}`);
      }
    }
  }, []);

  // Check lock status if currentLock gets updated
  useEffect(() => {
    if (currentLock) {
      doorStatusApi();
    }
  }, [currentLock]);

  const handleTabChange = (eventKey) => {
    // Update the URL with the active tab query parameter
    window.history.pushState(
      {},
      "",
      `/property/${params.id}?activeTab=${eventKey}`
    );
    setActiveTab(eventKey);
  };

  const loadHardware = (hardware) => {
    // Load Cameras
    let cameras = hardware.filter((hw) => hw.category === "camera");
    setCameras(cameras);

    const newArray = cameras.map((obj) => ({ [obj._id]: "Stream error" }));

    setCameraUrl(() => newArray);

    const toastObj = {};

    cameras.forEach((element) => {
      toastObj[element._id] = false;
    });

    setShowToast(toastObj);

    // Check if the property has a lock
    let lock = hardware.find((hw) => hw.category === "lock");
    setCurrentLock(lock);
  };

  function getPropertyByID(id) {
    setProperty({}); //clear the existing property
    setCameras([]); // clear existing cameras

    const processData = (data) => {
      setProperty(data);
      if (data.kit) {
        // if (!subscriptions[response.data.kit.device_id]) {
        //   // subscribeToPubSub(response.data.kit.device_id);
        //   if (response.data.kit.hardware) {
        //     loadHardware(response.data.kit.hardware)
        //   }
        // } else {
        //   if (response.data.kit.hardware) {
        //     loadHardware(response.data.kit.hardware)
        //   }
        // }

        if (data.kit.hardware) {
          loadHardware(data.kit.hardware);
        }
      }
    };

    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${process.env.REACT_APP_NODE_API_URL}/api/property/test/${id}`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };
      handleGetProperty(requestConfig, processData);
    });
  }

  useEffect(() => {
    if (propertyError) {
      const showAlert = () =>
        setAlertModal({
          show: true,
          title: propertyError.title,
          content: propertyError.content,
        });
      showAlert();
    }
  }, [propertyError]);

  /* 
        To-Do : Revame the logic to handle error condition better 
    */
  function doorStatusApi() {
    // While the button is refreshing
    setIsRefreshing(true);
    setLoadingLockState(true);

    Auth.currentSession().then((token) => {
      const lock_status_url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/${currentLock?._id}/status`;
      const options = {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };

      axios
        .get(lock_status_url, options)
        .then((response) => {
          let buttonColor = "";
          if (response.status === 200) {
            setDoorStatus(response.data.doorStatus)
            if (response.data.lockStatus === "Open") {
              setLockStatus(true);
              buttonColor = "bg-green";
              setLockUncalibrated(false);
            } else if (response.data.lockStatus === "Locked") {
              setLockStatus(false);
              buttonColor = "bg-red";
              setLockUncalibrated(false);
            } else if (
              response.data.lockStatus === "uncalibrated|locked" ||
              response.data.lockStatus === "uncalibrated|unlocked"
            ) {
              setLockUncalibrated(true);
            }
            setLockError("");
          }
          setIsRefreshing(false);

          if (response.status >= 490 && response.status <= 494) {
            console.error(response.data);
            setLockError(response.data);
          }
        })
        .catch((error) => {
          //setLockError(error.response.data.message ?? 'This property has no associated lock');
          console.error("ERROR: ", error);
        })
        .finally(() => {
          setIsRefreshing(false);
          setLoadingLockState(false);
        });
    });
  }

  const handleLock = async (action) => {
    setShowLockConfirmation(false); // Hide Lock confirmation modal

    try {
      setLoadingLockState(true); // Locking/unlocking in process

      const lock_action_url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/${currentLock._id}/${action}`;

      const token = await Auth.currentSession();
      const options = {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };

      const response = await axios.put(lock_action_url, {}, options);
      setLoadingLockState(false); // Locking/unlocking no longer in process

      // Successful locking/unlocking
      if (response.status === 200) {
        if (response.data.status === "unlocked") {
          setLockStatus(true);
        } else if (response.data.status === "locked") {
          setLockStatus(false);
        }
        setLockError(""); // No error
      } else {
        throw new Error("Lock not connected. Please contact support.");
      }
    } catch (error) {
      setLoadingLockState(false);

      console.error("ERROR: ", error);
      if (
        error.response &&
        error.response.status &&
        error.response.status === 491
      ) {
        // Hub Offline
        setLockError("Hub is offline.");
      } else {
        // Other errors
        setLockError("Lock not connected. Please contact support.");
      }
    }
  };

  const tooltip = (text) => (
    <Tooltip id="tooltip">
      <>{text}</>
    </Tooltip>
  );

  return (
    <>
      <Sidebar>
        <div className="property-head bg-delet-pink">
          <Row
            className="align-items-top pb-4 pt-4 m-0"
            style={{
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            <Col>
              <div className="d-flex align-items-center flex-wrap flex-xl-nowrap justify-content-center justify-content-xl-start">
                <h2>Properties</h2>
                <ChevronRight className="mx-2" />
                <div className="d-flex align-items-center">
                  <span className="body-bold py-1">
                    {removeUndefinedWord(property?.shortAddress)}
                    {property?.unit &&
                      property?.unit !== "" &&
                      `, Unit ${property.unit}`}
                  </span>
                </div>
              </div>
            </Col>

            {/* Lock button and Edit property button */}
            <Col className="d-flex gap-3 justify-content-end">
              <div className="d-flex gap-3 justify-content-end align-items-center">
                {currentLock && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={tooltip("Refresh lock status")}
                  >
                    <span>
                      <MdRefresh
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                        onClick={() => doorStatusApi()}
                        className={`${isRefreshing ? "rotate" : ""}`}
                      />
                    </span>
                  </OverlayTrigger>
                )}

                <LockButton
                  status={lockStatus}
                  lockUncalibrated={lockUncalibrated}
                  loading={loadingLockState}
                  isRefreshing={isRefreshing}
                  lockError={lockError}
                  currentLock={currentLock}
                  onClick={() => setShowLockConfirmation(true)}
                />

                <DoorStatus status={doorStatus} />
              </div>
            </Col>
          </Row>
        </div>

        {propertyLoading && !propertyError ? (
          <div className="d-flex justify-content-center">
            <LoaderRing height="100" width="100" />
          </div>
        ) : (
          <div>
            <Tabs
              defaultActiveKey={activeTab}
              onSelect={handleTabChange}
              style={{ paddingLeft: "32px", backgroundColor: "#EAEAEB" }}
            >
              <Tab
                eventKey="details"
                title="Property Details"
                className="container-div"
              >
                <Details />
              </Tab>
              <Tab eventKey="cameras" title="Camera Feed">
                <CameraFeed />
              </Tab>
              <Tab
                eventKey="leads"
                title={`Leads (${leadsCount.all ? leadsCount.all : 0})`}
              >
                <Leads />
              </Tab>
              {userData?.role !== "agent" && (
                <Tab eventKey="system" title="Delet System Settings">
                  <System />
                </Tab>
              )}
              {userData?.role !== "agent" && (
                <Tab eventKey="users" title="Users">
                  <Users />
                </Tab>
              )}
            </Tabs>
          </div>
        )}
      </Sidebar>

      {/* {lockStatus ? 'Yes, Lock Door' : 'Yes, Unlock Door'} */}
      <DoorConfirmation
        show={showLockConfirmation}
        status={lockStatus}
        loading={loadingLockState}
        onCancel={() => setShowLockConfirmation(false)}
        onConfirm={() =>
          lockStatus ? handleLock("lock") : handleLock("unlock")
        }
        address={removeUndefinedWord(property.address)}
        unit={property.unit}
      />
    </>
  );
};

export default PropertiesList;
