import Sidebar from "../../components/SideBar";
import Orders from "./tabs/Orders";
import { Link, useNavigate, useParams } from "react-router-dom";
import KitsTab from "./tabs/KitsTab";

const Kits = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  return (
    <Sidebar>
      <section className="min-h-100vh d-flex flex-column gap-2">
        <header className="d-flex justify-content-between align-items-center" style={{backgroundColor: '#EAEAEB', height: '126px'}}>
          <h4 className="fs-3 ms-5 fw-bold pt-0">
            {tab === 'orders' ? 'Orders' : 'Kits'}
          </h4>

          <Link
            className="bg-delet text-white p-2 fw-bold fs-5 border-0 rounded-3 text-decoration-none d-none"
            to="/order"
          >
            + Order New Kit
          </Link>
        </header>

        <ul className="nav nav-underline ms-3">
          <li className="nav-item">
            <button
              style={{
                color: (!tab || tab === "kits") ? '#5271ff' : 'grey', 
              }}
              className={`nav-link ${(!tab || tab === "kits") && "active text-delet-blue"}`}
              type="button"
              onClick={() => navigate("/kits/kits")}
            >
              KITS
            </button>
          </li>
          <li className="nav-item">
            <button
              style={{
                color: tab === "orders" ? '#5271ff' : 'grey',
              }}
              className={`nav-link ${tab === "orders" && "active text-delet-blue"}`}
              onClick={() => navigate("/kits/orders")}
            >
              ORDERS
            </button>
          </li>
        </ul>
        {tab === "orders" ? <Orders /> : <KitsTab />}
      </section>
    </Sidebar>
  );
};
export default Kits;
