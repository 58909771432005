import React from "react";
import { BiSolidDoorOpen } from "react-icons/bi";
import { MdSensorDoor } from "react-icons/md";
import { TbDoorOff } from "react-icons/tb";

const DoorStatus = ({ status }) => {
  return (
    <div
      className={`rounded-circle align-items-center justify-content-center p-3 ${
        status === "open"
          ? "bg-green"
          : status === "closed"
          ? "bg-red"
          : "bg-dark-subtle"
      } ${
        process.env.REACT_APP_ENVIRONMENT === "development"
          ? "d-flex"
          : "d-none"
      }`}
    >
      {status === "open" ? (
        <BiSolidDoorOpen color="white" size={30} />
      ) : status === "closed" ? (
        <MdSensorDoor color="white" size={30} />
      ) : (
        <TbDoorOff color="#939393" size={25} />
      )}
    </div>
  );
};

export default DoorStatus;
