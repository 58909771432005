import "./Properties.scss";

import React, { useEffect, useMemo, useState } from "react";

import {
  propertyStatus,
  propertyFilterStatus,
  propertyType,
  propertyListMenuItems,
} from "../../static/Data";
import StyledSelect from "../../components/Status";
import Button from "../../components/Button";
import { PlusCircle, MoreVertical } from "react-feather";
import CustomDropdown from "../../components/Dropdown";
import TabletTable from "./components/Tablet/Tablet-Table";
import MobileTable from "./components/Mobile/Mobile-Table";
import axios from "axios";
import copy from "copy-to-clipboard";
import moment from "moment";
import { propertyBookingLink } from "../../utils/Properties";
import {
  Button as BootstrapButton,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import ReactGA from "react-ga4";

import Sidebar from "../../components/SideBar";
import PagePagination from "../../components/Pagination";
import { IoMdArrowUp } from "react-icons/io";
import { IoMdArrowDown } from "react-icons/io";

//------------alplify imports----------------//
import { Auth } from "aws-amplify";
import { useGeneral } from "../../context/GeneralContext";
import propertyDummyImage from "../../assests/images/6-thumb.png";
import getImageUrl from "../../helper/checkImageFormat";
import removeUndefinedWord from "../../helper/updateString";
import ButtonDropdown from "../../components/Buttons/ButtonDropdown";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { Center, Loader } from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getProperties, patchProperty } from "../../api/properties";
import LazyImgWithPlaceholder from "../../components/Images/LazyImgWithPlaceholder";
import UnstyledButton from "../../components/UnstyledButton";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import PropertyStatusSelect from "./components/PropertyStatusSelect";

const initState = {
  filterBy: {
    search: "",
    status: ["active"],
    classification: [],
  },
  sort: {
    by: "",
    order: false,
  },
};

const PropertiesList = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertModal, timezone } = useGeneral();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [searchParam, setSearchParam] = useState("");
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  const [key, setkey] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const [filterBy, setFilterBy] = useState(initState.filterBy);
  const [appliedFilters, setAppliedFilters] = useState(initState.filterBy);

  const [sortBy, setSortBy] = useState(initState.sort);
  const params = useParams();
  const location = useLocation();
  const isEditPage = location.pathname.includes("edit");


  const queryKey = useMemo(() => {
    return [
      "properties",
      actualPage,
      appliedFilters.search,
      appliedFilters.status.join(","),
      appliedFilters.classification.join(","),
      sortBy.by,
      sortBy.order,
    ];
  }, [actualPage, appliedFilters, sortBy]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    data = { properties: [] },
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getProperties({
        page: actualPage,
        filters: appliedFilters,
        sort: sortBy,
      }),
    keepPreviousData: true,
  });

  const { mutateAsync: handleChangeStatus, isPending: updatingType } =
    useMutation({
      mutationFn: ({ propertyId, updatedFields }) =>
        patchProperty(propertyId, updatedFields),
      onSuccess: (updatedProperty) => {
        queryClient.invalidateQueries(queryKey);
      },
    });

  const handleDeleteProperty = (id) => {
    Auth.currentSession().then((token) => {
      axios
        .delete(`${process.env.REACT_APP_NODE_API_URL}/api/property/${id}`, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAlertModal({
              show: true,
              title: "Deleted",
              content: "Property deleted successfully",
            });
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error deleting property",
            });
          }
        })
        .catch((error) => {
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error deleting property",
          });
        })
        .finally(refetch);
    });
  };

  const getKey = async (e, id) => {
    setkey(id);
  };

  const handleFilter = (value, type) => {
    const typeValues = filterBy[type];
    if (typeValues.includes(value)) {
      const updatedValues = typeValues.filter((item) => item !== value);
      setFilterBy({ ...filterBy, [type]: updatedValues });
    } else {
      setFilterBy({ ...filterBy, [type]: [...typeValues, value] });
    }
  };

  const handleSort = (by) => {
    if (by === sortBy.by) return setSortBy({ by, order: !sortBy.order });
    return setSortBy({ by, order: true });
  };

  const reportShareLink = (category, label) => {
    ReactGA.event({
      action: "share_link",
      category: category,
      label: label,
    });
  };

  const handleNextPage = (nextPage) => {
    if (data.totalPages >= nextPage && nextPage > 0) {
      setActualPage(nextPage);
    }
  };

  const handleMenuItemClick = async (action, id) => {
    if (action === "Edit property details") {
      navigate(`/property/edit/${id}`);
    } else if (action === "Open prospect booking page") {
      const property = data.properties.find((property) => property._id === id);
      const link = await propertyBookingLink(property);
      window.open(link, "_blank");
    } else if (action === "Copy prospect booking link") {
      const property = data.properties.find((property) => property._id === id);
      const link = await propertyBookingLink(property);
      copy(link);
      setAlertModal({
        show: true,
        title: "Copied",
        content: "Sharing the link enables lead booking for this Unit",
      });
    } else if (action === "View all property leads") {
      navigate(`/property/${id}?activeTab=leads`);
    } else if (action === "Delete property") {
      handleDeleteProperty(id);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setError(false);
  };

  const handleClose = () => {
    setShowImportModal(false);
    setError(false);
    setValidationError(false);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setError(true);
      return;
    }

    try {
      const parsedData = await new Promise((resolve) => {
        Papa.parse(selectedFile, {
          complete: (result) => {
            resolve(result.data);
          },
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
      });

      if (
        parsedData.some(
          (item) =>
            !item.address ||
            !item.aptOrSuite ||
            !item.city ||
            !item.state ||
            !item.zipCode
        )
      ) {
        setValidationError(true);
        return;
      }

      setShowImportModal(false);
      setValidationError(false);
      await submitData(parsedData);
    } catch (error) {
      console.error("Error parsing CSV:", error);
      setError(true);
    }
  };

  const mapUtilityValue = (value) =>
    value === "Included In Rent" ? "included" : "tenant";
  const convertToSecond = (ele) => Number(ele?.split(" ")[0]) * 60 * 1000;
  const mapParkingType = (type) => {
    switch (type) {
      case "Street Parking (Standard)":
        return "streetStandard";
      case "Street Parking (Residential Permit Enforced)":
        return "streetResidential";
      case "Uncovered Parking":
        return "uncovered";
      case "Covered Parking":
        return "covered";
      case "Secured Access Garage":
        return "secured";
      default:
        return "";
    }
  };

  const submitData = async (data) => {
    if (!data.length) return;
    for (const jsonData of data) {
      let payload = {
        amenitiesAndFeatures: {
          floorPlanHighlights: [],
          kitchenFeatures: [],
          buildingFeatures: [],
        },
        unit: jsonData.aptOrSuite ? jsonData.aptOrSuite?.toString() : "",
        propertyType: jsonData.propertyType || "apartment",
        category: jsonData.rentOrSale || "rent",
        sqft: jsonData.sqft ? jsonData.sqft?.toString() : "",
        bedrooms: jsonData.numberOfBedrooms
          ? jsonData.numberOfBedrooms?.toString()
          : "",
        bathrooms: jsonData.numberOfBathrooms
          ? jsonData.numberOfBathrooms?.toString()
          : "",
        propertyDescription: jsonData.propertyDescription || "",
        utilities: {
          water: mapUtilityValue(jsonData.water)
            ? mapUtilityValue(jsonData.water)
            : "tenant",
          electricity: mapUtilityValue(jsonData.electricity)
            ? mapUtilityValue(jsonData.electricity)
            : "tenant",
          gas: mapUtilityValue(jsonData.gas)
            ? mapUtilityValue(jsonData.gas)
            : "tenant",
          trash: mapUtilityValue(jsonData.trash)
            ? mapUtilityValue(jsonData.trash)
            : "tenant",
          sewage: mapUtilityValue(jsonData.sewage)
            ? mapUtilityValue(jsonData.sewage)
            : "tenant",
          notes: jsonData.utilitiesNotes || "",
        },
        parking: {
          type: mapParkingType(jsonData.parkingType)
            ? mapParkingType(jsonData.parkingType)
            : "streetResidential",
          spacesNumber: jsonData.numberOfParkingSpaces
            ? jsonData.numberOfParkingSpaces?.toString()
            : "",
          monthlyCostPerSpace: jsonData.monthlyCostPerSpace
            ? jsonData.monthlyCostPerSpace?.toString()
            : "",
          included: jsonData.parkingIncludedInRent,
          notes: jsonData.parkingNotes || "",
        },
        pets: {
          allowed:
            jsonData.petsAllowed === "No pets allowed" ? true : false || true,
          dogs: {
            allowed:
              jsonData.petsAllowed === "Yes, dogs allowed" ? true : false,
            weight: jsonData.dogsWeightLimit
              ? jsonData.dogsWeightLimit?.toString()
              : "",
            maxAllowed: jsonData.dogsMaxNumberOfDogsAllowed
              ? jsonData.dogsMaxNumberOfDogsAllowed?.toString()
              : "",
          },
          cats: {
            allowed:
              jsonData.petsAllowed === "Yes, cats allowed" ? true : false,
            weight: jsonData.catsWeightLimit
              ? jsonData.catsWeightLimit?.toString()
              : "",
            maxAllowed: jsonData.catsMaxNumberOfCatsAllowed
              ? jsonData.catsMaxNumberOfCatsAllowed?.toString()
              : "",
          },
          deposit: jsonData.petDeposit ? jsonData.petDeposit?.toString() : "",
          monthlyPetRent: jsonData.monthlyPetRent
            ? jsonData.monthlyPetRent?.toString()
            : "",
          notes: jsonData.petPolicyNotes?.toString() || "",
        },
        rentalApplicationForm: {
          url: jsonData.applicationURL
            ? jsonData.applicationURL?.toString()
            : "",
          fee: jsonData.applicationFee
            ? jsonData.applicationFee?.toString()
            : "",
          instructions: jsonData.applicationInstructions?.toString() || "",
          enable: jsonData.rentalApplicationFormAndInstructions,
        },
        timeInstructions: convertToSecond(jsonData.advanceProspectsTime),
        company: {
          name: jsonData.propertyManagementGroup || "",
          website: jsonData.companyWebsite || "",
          phone: jsonData.companyPhoneNumber
            ? jsonData.companyPhoneNumber?.toString()
            : "",
          contactInformation:
            jsonData.includeCompanyContactInformationInProspectCommunication,
        },
        leasingAgent: {
          firstName: jsonData.agentFirstName || "",
          lastName: jsonData.agentLastName || "",
          phone: jsonData.agentPhoneNumber
            ? jsonData.agentPhoneNumber?.toString()
            : "",
          email: jsonData.agentEmail || "",
          contactInformation:
            jsonData.includeAgentContactInformationInProspectCommunication,
        },
        propertyManager: {
          onSite:
            jsonData.propertyOrBuildingManagerOnSite === "Yes" ? true : false,
          firstName: jsonData.managerFirstName || "",
          lastName: jsonData.managerLastName || "",
          phone: jsonData.managerPhoneNumber
            ? jsonData.managerPhoneNumber?.toString()
            : "",
          email: jsonData.managerEmail || "",
          contactInformation:
            jsonData.includeManagerContactInformationInProspectCommunication,
        },
        maintenanceManager: {
          onSite: jsonData.maintenanceManagerOnSite === "Yes" ? true : false,
          firstName: jsonData.maintenanceManagerFirstName || "",
          lastName: jsonData.maintenanceManagerLastName || "",
          phone: jsonData.maintenanceManagerPhoneNumber
            ? jsonData.maintenanceManagerPhoneNumber?.toString()
            : "",
          email: jsonData.maintenanceManagerEmail || "",
          contactInformation:
            jsonData.includeMaintenanceManagerContactInformationInProspectCommunication,
        },
        kit: null,
        price: jsonData.monthlyRent ? jsonData.monthlyRent?.toString() : "",
        deposit: jsonData.securityDeposit
          ? jsonData.securityDeposit?.toString()
          : "",
        shortAddress: jsonData.address || "",
        city: jsonData.city || "",
        zipCode: jsonData.zipCode || "",
        state: jsonData.state || "",
        address: jsonData.address || "",
        dateAvailableTs: jsonData.availableMoveInDate
          ? new Date(
              jsonData.availableMoveInDate.replace(
                /(\d{2})-(\d{2})-(\d{4})/,
                "$3-$1-$2"
              )
            ).getTime()
          : new Date().getTime(),
        primaryImage: "",
        prospectIdentityVerification: jsonData.idOrDriversLicenseVerification,
        doorUnlockLink: jsonData.doorUnlockLink,
        leaseTermOptions: [
          jsonData.leaseTermOptions ? jsonData.leaseTermOptions : "",
        ],
        propertyAccessCodes: {
          buildingCode: jsonData.buildingAccessCode || "",
          elevatorCode: jsonData.elevatorCode || "",
          instructions: jsonData.additionalInstructions || "",
          enable: jsonData.propertyAccessCodesAndInstructions,
        },
      };

      let methodForm = "";
      let url = "";
      if (isEditPage) {
        methodForm = `put`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/property/${params.id}`;
      } else {
        methodForm = `post`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/property`;
      }
      Auth.currentSession().then((token) => {
        axios({
          method: methodForm,
          url: url,
          data: payload,
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
          .then((response) => {
            if (isEditPage) {
              setAlertModal({
                show: true,
                title: "Success",
                content: "Property Edited Successfully",
              });
              navigate(`/property/${params.id}`);
            } else {
              setAlertModal({
                show: true,
                title: "Success",
                content: "Property Created Successfully",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            if (isEditPage) {
              setAlertModal({
                show: true,
                title: "Error",
                content: "Error Editing Property",
              });
            } else {
              setAlertModal({
                show: true,
                title: "Error",
                content: "Error Creating Property",
              });
            }
          });
      });
    }
  };

  const [showCopyToClipboard, setShowCopyToClipboard] = useState(false);

  const handleEnterInSearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setAppliedFilters({
        ...appliedFilters,
        search: filterBy.search,
      });
      setActualPage(1);
    }
  };
  return (
    <Sidebar>
      <div className="table-heading">
        <div className="d-flex justify-content-between align-items-center px-2 ">
          <h2>Properties</h2>
          <div>
            <ButtonDropdown
              icon={<PlusCircle size="15px"/>}
              items={[
                {
                  name: "Add Manually",
                  handleClick: () => navigate("/property/add"),
                },
                {
                  name: "Import",
                  handleClick: () => setShowImportModal(true),
                },
              ]}
            />
          </div>
        </div>
        {screenWidth <= 755 && (
          <div className="filters-mobile ">
          
                <Form className="w-100 " style={{width:'100%'}}>
                  <Form.Group
                    className="search-control-group d-flex"
                    controlId="search-control"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={filterBy.search}
                      onChange={(e) => {
                        setFilterBy({ ...filterBy, search: e.target.value });
                      }}
                      onKeyDown={handleEnterInSearch}
                      className="w-100"
                    />
                    <BootstrapButton
                      className={
                        searchParam !== "" ? "search-btn active" : "search-btn"
                      }
                      onClick={() =>
                        setAppliedFilters({
                          ...appliedFilters,
                          search: filterBy.search,
                        })
                      }
                    ></BootstrapButton>
                  </Form.Group>
                </Form>
                <Dropdown className=" align-items-center d-flex " show={showFilterDropdown}>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className="border no-arrow   d-flex align-items-center justify-content-center"
                    drop="none"
                    onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                      style={{background:"#5271FF", height:"40.38px", width:'40.38px', color:'white', borderRadius:'100%'}}
                  >
                    <TbAdjustmentsHorizontal size={30} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#/action-1"
                      onClick={(e) => e.stopPropagation()}
                      className="no-bg"
                    >
                      <span className="fw-bold fs-7">Type</span>
                      <div className="d-flex flex-column border-top pt-2 mt-2">
                        {propertyType.map((type, i) => (
                          <Form.Check
                            key={i}
                            type="checkbox"
                            id={`default-${type.value}`}
                            label={type.label}
                            value={type.value}
                            onChange={() =>
                              handleFilter(type.value, "classification")
                            }
                            checked={filterBy.classification.includes(
                              type.value
                            )}
                            className="border-bottom pb-2"
                          />
                        ))}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-2"
                      onClick={(e) => e.stopPropagation()}
                      className="no-bg d-flex flex-column"
                    >
                      <strong>Status</strong>
                      <div className="d-flex flex-column border-top pt-2 mt-2">
                        {propertyFilterStatus.map((status, i) => (
                          <Form.Check
                            key={i}
                            type="checkbox"
                            id={`default-${status.value}`}
                            label={status.label}
                            value={status.value}
                            onChange={() =>
                              handleFilter(status.value, "status")
                            }
                            checked={filterBy.status.includes(status.value)}
                            className="border-bottom pb-2"
                          />
                        ))}
                      </div>
                    </Dropdown.Item>

                    <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                      <UnstyledButton
                        className="text-delet-blue fw-bold"
                        onClick={() => {
                          setShowFilterDropdown(false);
                          setAppliedFilters(filterBy);
                        }}
                      >
                        Apply filters
                      </UnstyledButton>

                      <UnstyledButton
                        className="text-body-tertiary"
                        onClick={() => {
                          setAppliedFilters({
                            ...initState.filterBy,
                            status: [],
                          });
                          setFilterBy({ ...initState.filterBy, status: [] });
                          setShowFilterDropdown(false);
                        }}
                      >
                        Clear all
                      </UnstyledButton>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                {showCopyToClipboard && <div>Link copied to clipboard!</div>}
          </div>
        )}
      </div>
      {    // MARK: Import Modal
      }
      <Modal
        centered
        show={showImportModal}
        size="lg"
        dialogClassName="modal-dialog"
      >
        <Modal.Header>
          <Modal.Title>Import CSV File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mt-2">
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
          {error && (
            <label style={{ color: "red" }}>Import your CSV file</label>
          )}
          {validationError && (
            <label style={{ color: "red" }}>
              Fields address, aptOrSuite, city, state, zipCode are empty.
            </label>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <>
        <div className="px-md-5">
            {/*
              MARK: Desktop filters
            */}
          {screenWidth > 755 && (
            <div className="filters align-items-center">
              <Row className="align-items-center">
                <Col className="d-flex gap-2 justify-content-between">
                  <Form className="col-8">
                    <Form.Group
                      className="search-control-group d-flex"
                      controlId="search-control"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        value={filterBy.search}
                        onChange={(e) => {
                          if (e.target.value === "") setAppliedFilters({...appliedFilters, search: ""})
                          setFilterBy({ ...filterBy, search: e.target.value });
                        }}
                        onKeyDown={handleEnterInSearch}
                        className="w-100"
                      />
                      <BootstrapButton
                        className={
                          searchParam !== "" ? "search-btn active" : "search-btn"
                        }
                        onClick={() =>
                          setAppliedFilters({
                            ...appliedFilters,
                            search: filterBy.search,
                          })
                        }
                      ></BootstrapButton>
                    </Form.Group>
                  </Form>
                  <Dropdown className="col-3" show={showFilterDropdown}>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      className="border no-arrow h-100 px-5 w-100 d-flex gap-1 align-items-center"
                      drop="none"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    >
                      <TbAdjustmentsHorizontal size={18} />
                      Filter by
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={(e) => e.stopPropagation()}
                        className="no-bg"
                      >
                        <span className="fw-bold fs-7">Type</span>
                        <div className="d-flex flex-column border-top pt-2 mt-2">
                          {propertyType.map((type, i) => (
                            <Form.Check
                              key={i}
                              type="checkbox"
                              id={`default-${type.value}`}
                              label={type.label}
                              value={type.value}
                              onChange={() =>
                                handleFilter(type.value, "classification")
                              }
                              checked={filterBy.classification.includes(
                                type.value
                              )}
                              className="border-bottom pb-2"
                            />
                          ))}
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-2"
                        onClick={(e) => e.stopPropagation()}
                        className="no-bg d-flex flex-column"
                      >
                        <strong>Status</strong>
                        <div className="d-flex flex-column border-top pt-2 mt-2">
                          {propertyFilterStatus.map((status, i) => (
                            <Form.Check
                              key={i}
                              type="checkbox"
                              id={`default-${status.value}`}
                              label={status.label}
                              value={status.value}
                              onChange={() =>
                                handleFilter(status.value, "status")
                              }
                              checked={filterBy.status.includes(status.value)}
                              className="border-bottom pb-2"
                            />
                          ))}
                        </div>
                      </Dropdown.Item>

                      <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                        <UnstyledButton
                          className="text-delet-blue fw-bold"
                          onClick={() => {
                            setShowFilterDropdown(false);
                            setActualPage(1);
                            setAppliedFilters(filterBy);
                          }}
                        >
                          Apply filters
                        </UnstyledButton>

                        <UnstyledButton
                          className="text-body-tertiary"
                          onClick={() => {
                            setAppliedFilters({
                              ...initState.filterBy,
                              status: [],
                            });
                            setFilterBy({ ...initState.filterBy, status: [] });
                            setShowFilterDropdown(false);
                          }}
                        >
                          Clear all
                        </UnstyledButton>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  {showCopyToClipboard && <div>Link copied to clipboard!</div>}
                </Col>
              </Row>
            </div>)}
          {screenWidth > 1200 ? (
            // MARK: Desktop Table
            <Table className="property-table">
              <thead>
                <tr>
                  <th className="text-start">Property details</th>
                  <th
                    className="text-start"
                    onClick={() => handleSort("price")}
                    onDoubleClick={() => setSortBy(initState.sort)}
                  >
                    <span className="d-flex">
                      Price
                      <span>
                        {sortBy.by === "price" ? (
                          sortBy.order ? (
                            <IoMdArrowUp style={{ color: "#5d5fef" }} />
                          ) : (
                            <IoMdArrowDown style={{ color: "#5d5fef" }} />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-start"
                    onClick={() => handleSort("leadsCount")}
                    onDoubleClick={() => setSortBy(initState.sort)}
                  >
                    <span className="d-flex">
                      Leads
                      <span>
                        {sortBy.by === "leadsCount" ? (
                          sortBy.order ? (
                            <IoMdArrowUp style={{ color: "#5d5fef" }} />
                          ) : (
                            <IoMdArrowDown style={{ color: "#5d5fef" }} />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-center"
                    onClick={() => handleSort("listedTs")}
                    onDoubleClick={() => setSortBy(initState.sort)}
                  >
                    <span className="d-flex text-nowrap">
                      Listed date
                      <span>
                        {sortBy.by === "listedTs" ? (
                          sortBy.order ? (
                            <IoMdArrowUp style={{ color: "#5d5fef" }} />
                          ) : (
                            <IoMdArrowDown style={{ color: "#5d5fef" }} />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                  <th className="text-nowrap">Property status</th>
                  <th className="text-end">More actions</th>
                </tr>
              </thead>
              {isLoading ? (
                <Center h={100}>
                  <Loader />
                </Center>
              ) : isError ? (
                <div>Error getting properties</div>
              ) : (
                <tbody>
                  {data.properties.map((element, index) => (
                    <tr key={element._id} className="clickable-row">
                      <Link
                        to={`/property/${element._id}`}
                        className="row-link"
                        style={{ display: "contents" }}
                      >
                        <td className="image-td">
                          <div className="">
                            <Link
                              to={`/property/${element._id}`}
                              className="properties-details-linkImage"
                            >
                              <span className="position-relative d-inline-block">
                                <LazyImgWithPlaceholder
                                  width={224}
                                  height={126}
                                  placeholder={propertyDummyImage}
                                  src={
                                    element.images?.[0] ??
                                    getImageUrl(element.primaryImage)
                                  }
                                  alt="property"
                                />
                              </span>
                            </Link>
                          </div>
                          <div className="d-flex flex-column image-content pe-2">
                            <span className="type">
                              {element.classification}
                            </span>
                            <span className="property-address">
                              {element.shortAddress || element.city || element.state || element.zipCode ? 
                                `${element.shortAddress}${element.unit ? ` Unit ${element.unit}` : ''}, ${element.city}, ${element.state} ${element.zipCode}` : 
                                ''}
                            </span>
                            <div className="d-flex flex-row gap-2">
                              <span className="property-tags">
                                <span className="body-xs-b">
                                  {typeof element.bedrooms === "number" &&
                                    `${element.bedrooms}BR`}
                                </span>
                              </span>
                              <span className="property-tags">
                                <span className="body-xs-b">
                                  {element.bathrooms &&
                                    `${element.bathrooms}BA`}
                                </span>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="price">${element.price}</span>
                        </td>
                        <td>
                          <span className="leads">{element.leadsCount}</span>
                        </td>
                        <td>
                          <span className="date">
                            {element.listedTs
                              ? moment(element.listedTs).format("MM/DD/YYYY")
                              : "No Listed Date"}
                          </span>
                        </td>
                      </Link>
                      <td onMouseOver={(e) => getKey(e, element._id)}>
                        <PropertyStatusSelect
                          propertyId={element._id}
                          queryKey={queryKey}
                          status={element.status}
                        />
                      </td>
                      <td>
                        <div className="more-actions">
                          <CustomDropdown
                            icon={<MoreVertical />}
                            menuItems={propertyListMenuItems}
                            onMenuItemClick={(action) =>
                              handleMenuItemClick(action, element._id)
                            }
                            style={{ pointerEvents: "auto" }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          ) : screenWidth > 755 ? (
            // MARK: Tablet Table
            <TabletTable
              list={data.properties}
              timezone={timezone}
              getKey={getKey}
              handleChangeStatus={handleChangeStatus}
              propertyStatus={propertyStatus}
            />
          ) : (
            // MARK: Mobile Table
            <MobileTable
              list={data.properties}
              timezone={timezone}
              getKey={getKey}
              handleChangeStatus={handleChangeStatus}
              propertyStatus={propertyStatus}
            />
          )}
            {/*
              MARK: Pagination
            */}
          {data?.totalPages > 1 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PagePagination
                totalPages={data.totalPages}
                actualPage={actualPage}
                handlePageChange={handleNextPage}
              />
            </div>
          )}
        </div>
      </>
    </Sidebar>
  );
};

export default PropertiesList;
