import { useState } from "react";
import "./Showings.scss";
import "react-datepicker/dist/react-datepicker.css";

import { Button, Col, Form, Row,  } from "react-bootstrap";

import dayGridPlugin from "@fullcalendar/daygrid/index.js";
import interactionPlugin from "@fullcalendar/interaction/index.js";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid/index.js";

import Sidebar from "../../components/SideBar";
import { Center, Loader } from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createMeeting, getBookings, postBooking } from "../../api/bookings";
import { toZonedTime } from "date-fns-tz";
import BookingInfo from "./components/BookingInfo";
import NewBookingForm from "./components/NewBookingForm";

const Showings = () => {
  const queryClient = useQueryClient();

  const [search, setSearch] = useState("");
  const [selectedBooking, setSelectedBooking] = useState({});
  const [showBookingInfo, setShowBookingInfo] = useState(false);
  const [showNewBooking, setShowNewBooking] = useState(false);

  const {
    data: bookings,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["bookings"],
    queryFn: async () => await getBookings(),
  });

  const {mutateAsync: createBooking, isPending: creatingBook, error: errorCreatingBooking} = useMutation({
    mutationFn: async (booking) => await postBooking(booking),
    onSuccess: (data) => {
      queryClient.setQueryData(['bookings'], (prev) => [...prev, data]);
      setShowNewBooking(false)
    },
  })

  const handleClickBooking = (bookingId) => {
    setSelectedBooking(bookings.find((booking) => booking._id === bookingId));
    setShowBookingInfo(true);
  };

  return (
    <Sidebar>
      <BookingInfo
        show={showBookingInfo}
        handleClose={() => setShowBookingInfo(false)}
        booking={selectedBooking}
      />

      <NewBookingForm
        show={showNewBooking}
        handleClose={() => setShowNewBooking(false)}
        handleSubmit={createBooking}
        isLoading={creatingBook}
        error={errorCreatingBooking}
      />
      <div className="page-main-head align-items-center">
        <Row className="align-items-center">
          <Col xl={8}>
            <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
              <h1 className="h4 color-black mr-20">Showings</h1>
              <div>
                <Button
                  size="sm"
                  className="btn btn-primary btn-sm d-none"
                  onClick={() => setShowNewBooking(true)}
                >
                  NEW
                </Button>
              </div>
              <Form className="w-100">
                <Form.Group
                  className="search-control-group d-flex "
                  controlId="search-control"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <Button className="search-btn"></Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
          {/* <Col xl={4}>
            <div className="d-flex align-items-center justify-content-md-end">
              <div className="tabs">
                <button className="border-1 p-1 rounded">
                  <img
                    src={require("../../assests/images/calendar-ico.png")}
                    alt="calendar"
                    width={12}
                    height={14}
                  />{" "}
                  Calendar
                </button>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
      <div className="pt-5">
        <Button
          variant="primary"
          onClick={() => console.log("handleShow")}
          id="canvasbtn"
          className="d-none"
        >
          Launch
        </Button>

        <div className="panels">
          {isLoading ? (
            <Center h={100}>
              <Loader />
            </Center>
          ) : (
            <div>
              <FullCalendar
                plugins={[
                  timeGridPlugin,
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                ]}
                initialView="timeGridWeek"
                headerToolbar={{
                  left: "",
                  center: "",
                  right:
                    "today,dayGridMonth,timeGridWeek,timeGridDay,prev,next,listWeek",
                }}
                eventClick={(calEvent) => handleClickBooking(calEvent.event.id)}
                events={bookings?.map((booking) => ({
                  id: booking._id,
                  title: booking.property?.address,
                  description: booking.property?.address,
                  start: toZonedTime(
                    new Date(booking.startTime * 1000),
                    "America/Los_Angeles"
                  ),
                  end: toZonedTime(
                    new Date((booking.startTime + 15 * 60) * 1000),
                    "America/Los_Angeles"
                  ),
                  backgroundColor: "#D7F3F3",
                  borderColor: "#D7F3F3",
                  textColor: "#1F2327",
                }))}
                dateClick={(info) => console.log({ info })}
                initialDate={new Date()}
                dayHeaderFormat={{
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                  separator: "/",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default Showings;
