import React, {useState} from 'react'
import './Mobile-Table.scss'
import {
  Card,
} from 'react-bootstrap';
import {
  Link,
} from 'react-router-dom';
import StyledSelect from '../../../../components/Status';
import { MoreVertical, Share } from 'react-feather';
import removeUndefinedWord from '../../../../helper/updateString';
import getImageUrl from '../../../../helper/checkImageFormat';
import styles from './styles.module.scss'
import FormModal from './../../../../components/Modals/FormModal';
const MobileTable = ({ list, imageUrl, minutesAgo, timezone, getKey, handleChangeStatus, propertyStatus, address }) => {
  const [showModal, setShowModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [formData, setFormData] = useState({location: '', fullName: '', email: '', phoneNumber: ''})


  const handleFormModal = () =>{
    setShowModal(true);
  }

  const handleModalClose = () =>{
    setShowModal(false);
    setIsVerified(false);
    setIsSubmitted(false);
    setFormData({ fullName: '', email: '', phoneNumber: ''})
    setPhoneError('')
    setEmailError('')
  }
  
  console.log(list)
  return (
    <>
    
    {list.map((element, index) => {
      return <div className={styles.container}>
        <div className={styles.innerContainer}>
          <input type="checkbox" />
          <Link to={`/property/${element._id}`}>
            <img
              src={element.images?.[0] ??
                getImageUrl(element.primaryImage)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = require('../../../../assests/images/1-placeholder.png');
              }} alt=""
            />
          </Link>
          <div className={styles.details}>
            {/* <p className={styles.classification}>{element.classification}</p> */}
            <div className={styles.innerDetails}>
              <h3>{element.address || 'No address'}</h3>
              <p>{element.city}</p>
            </div>
            <div className={styles.propertyMetadata}>
              <span><strong>Price: </strong><p>$4500</p></span>
              <span><strong>Leads: </strong><p>{element.leadsCount}</p></span>
              <span><strong>Listed On: </strong><p>{element.listedTs && new Date(element.listedTs).toLocaleDateString()}</p></span>
            </div>
          </div>
          <div className={styles.actionIconsContainer}>
            
            <Share
              className='more-icon'
              height={'10'}
              width={'10'}
              onClick={handleFormModal}
            />
          </div>
        </div>
        <hr />
      </div>
      
    })}
    <FormModal 
        show={showModal} 
        onHide={()=> handleModalClose()} 
        isVerified={isVerified} 
        isSubmitted={isSubmitted} 
        setIsVerified={setIsVerified} 
        setIsSubmitted={setIsSubmitted}
        formData={formData}
        setFormData={setFormData}
        emailError={emailError}
        setEmailError={setEmailError}
        phoneError={phoneError}
        setPhoneError={setPhoneError}
        />
    </>
  )
}

export default MobileTable