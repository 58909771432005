import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  CloseButton,
  Flex,
  Loader,
  Modal,
  rem,
  Text,
  TextInput,
} from "@mantine/core";

import UseHttp from "../../../hooks/UseHttp";
import { useGeneral } from "../../../context/GeneralContext";
import KitsTable from "../components/KitsTable";

import toolsIcon from "../../../assests/images/tools.svg";
import clearIcon from "../../../assests/images/clear.svg";

import { IoMdSearch } from "react-icons/io";
import { LuSlidersHorizontal } from "react-icons/lu";


import DatePicker from "react-datepicker";
import { format, setHours, setMinutes, setSeconds } from "date-fns";

import Dropdown from "react-bootstrap/Dropdown";
import { useQuery } from "@tanstack/react-query";
import { fetchKits } from "../../../api/kits";
import { statusClassMap } from "../../../helper/kits";
import StatusText from "../components/StatusText";
import { AppButton } from "../../../designSystem/AppButton";

const initState = {
  filterBy: {
    search: "",
    dateRange: {
      from: null,
      to: null,
    },
  },
};

const statusArr = [
  "received",
  "returned",
  "cancelled",
  "shipped",
  "arrived",
  "assigned",
  "pending",
];

const KitsTab = () => {
  const navigate = useNavigate();
  const { setAlertModal } = useGeneral();

  const [selectedKits, setSelectedKits] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [filterBy, setFilterBy] = useState(initState.filterBy);

  const [properties, setProperties] = useState([]);

  const {
    isLoading: loadingProperties,
    error: errorProperties,
    requestData: getProperties,
  } = UseHttp();

  const [modal, setModal] = useState({
    show: false,
    title: "",
    content: "",
  });

  const handleChange = (dates) => {
    const [start, end] = dates;
    const endOfDay = end
      ? setHours(setMinutes(setSeconds(end, 59), 59), 23)
      : end;
    setFilterBy((prev) => ({
      ...prev,
      dateRange: {
        from: start,
        to: endOfDay,
      },
    }));
    if (end) setIsOpen(false);
  };

  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseModal = () => {
    setModal({
      show: false,
      title: "",
      content: "",
      yesText: "",
      noText: "",
    });
  };

  const handleConfirm = (action) => {
    navigate(`/order/${action}/${selectedKits.join()}`);
  };

  const handleRequest = (action) => {
    setModal({
      show: true,
      title: (
        <Flex justify={"center"}>
          <Text tt={"capitalize"} size={'xl'} fw={'800'}>
            {action} kits 
          </Text>
        </Flex>
      ),
      content: (
        <>
        <Flex justify={'center'}>

          <Text c={'#999999'} size={'sm'}>{`Are you sure you want to request the ${
            action === "remove" ? "removal" : "intallation"
          } of this kit?`}</Text>
        </Flex>
          <Flex
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: 'sm', sm: 'lg' }}
          justify={{ sm: 'center' }}
          >
            <AppButton style={{width: '100%', height:'40px', marginTop:'20px'}} as='button' onClick={() => handleConfirm(action)}> Yes</AppButton>
            <AppButton style={{width: '100%', height:'40px', marginTop:'20px'}} hierarchy='secondary' as='button' mr={10} variant="light" onClick={handleCloseModal}>
              No
            </AppButton>
          </Flex>
        </>
      ),
    });
  };

  const clearFilters = () => {
    setFilterBy(initState.filterBy);
  };

  const {
    data: kits,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["kits"],
    queryFn: fetchKits,
  });

  useEffect(() => {
    if (error) {
      setAlertModal({
        show: true,
        title: "Error",
        content: "There was an error with the server",
      });
      console.log(error);
    }
  }, [error]);

  useEffect(() => {
    if (errorProperties) {
      setAlertModal({
        show: true,
        title: "Error",
        content: "There was an error with the server",
      });
      console.log(errorProperties);
    }
  }, [errorProperties]);

  const handleFilterStatus = (value, checked) => {
    if (checked) {
      setFilterBy((prev) => ({
        ...prev,
        state: prev.state ? [...prev.state, value] : [value],
      }));
    } else {
      setFilterBy((prev) => ({
        ...prev,
        state: prev.state ? prev.state.filter((item) => item !== value) : [],
      }));
    }
  };

  if (isLoading)
    return (
      <Center m={50}>
        <Loader color="blue" />
      </Center>
    );

  const filteredKits = kits.filter((kit) => {
    if (filterBy.search) {
      const searchLower = filterBy.search.toLowerCase();
      if (
        !kit.name.toLowerCase().includes(searchLower) &&
        (kit.state
          ? !kit.state.toLowerCase().includes(searchLower)
          : !"received".includes(searchLower)) &&
        !kit.property?.shortAddress.toLowerCase().includes(searchLower) &&
        !kit.assignedDate?.toString().includes(searchLower)
      ) {
        return false;
      }
    }

    if (filterBy.dateRange.from || filterBy.dateRange.to) {
      const { assignedDate } = kit;

      if (!assignedDate) return false;
      const from = filterBy.dateRange.from
        ? new Date(filterBy.dateRange.from).getTime()
        : null;
      const to = filterBy.dateRange.to
        ? new Date(filterBy.dateRange.to).getTime()
        : null;

      if ((from && assignedDate < from) || (to && assignedDate > to)) {
        return false;
      }
    }

    if (
      filterBy.state?.length > 0 &&
      !filterBy.state.includes(statusClassMap[kit.status ?? "received"])
    ) {
      return false;
    }
    return true;
  });

  return (
    <>
      <div className="d-flex gap-3 ms-3 align-items-center justify-content-between">
      <TextInput
          placeholder="Search..."
          className="flex-grow-1"
          style={{borderRadius: '20px'}}
          name="search"
          leftSection={
            <IoMdSearch
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
          value={filterBy.search}
          onChange={(e) => {
            setFilterBy((prev) => ({ ...prev, search: e.target.value }));
          }}
          rightSection={
            <CloseButton
              onClick={() => setFilterBy((prev) => ({ ...prev, search: "" }))}
            />
          }
        />

        <div className="position-relative">
          <button
            onClick={toggleDatePicker}
            className={`px-2 bg-white rounded d-flex align-items-center text-center gap-1 fw-bold py-1 ${
              isOpen
                ? "border-b-delet border-1 border-opacity-50"
                : "border border-dark-subtle"
            }`}
          >
            <LuSlidersHorizontal />
            {filterBy.dateRange.from && filterBy.dateRange.to
              ? `${format(filterBy.dateRange.from, "MM/dd/yyyy")} - ${format(
                  filterBy.dateRange.to,
                  "MM/dd/yyyy"
                )}`
              : "Filter By"}
          </button>
          {isOpen && (
            <div className="position-absolute">
              <DatePicker
                onChange={handleChange}
                startDate={filterBy.dateRange.from}
                endDate={filterBy.dateRange.to}
                selectsRange
                inline
                maxDate={new Date()}
                className=" shadow-none"
              />
            </div>
          )}
        </div>

        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="bg-white border-b-delet border-1 text-delet-blue d-none"
          >
            Status
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {statusArr.map((status) => (
              <div className="px-2 text-capitalize d-flex gap-2">
                <input
                  class="form-check-input me-1"
                  type="checkbox"
                  value={status}
                  id={status}
                  onChange={(e) =>
                    handleFilterStatus(e.target.value, e.target.checked)
                  }
                />
                <label
                  class="form-check-label d-flex gap-1 align-items-center"
                  for="firstCheckbox"
                >
                  <StatusText status={status} />
                </label>
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <button
          className="border-0 text-body-tertiary bg-white"
          onClick={clearFilters}
        >
          Clear all
        </button>

        <Button
          color="#25CC2B"
          style={{width: '125px'}}
          onClick={() => handleRequest("install")}
          disabled={!selectedKits.length}
        >
          
          <strong>Install</strong>
        </Button>
        <Button
          color="#4C4D52"
          style={{width: '125px', marginRight: '25px'}}
          onClick={() => handleRequest("remove")}
          disabled={!selectedKits.length}
        >
          <strong>Remove</strong>
        </Button>

        
      </div>

      <KitsTable
        kits={filteredKits}
        setSelectedKits={setSelectedKits}
        selectedKits={selectedKits}
        setModal={setModal}
        closeModal={handleCloseModal}
      />

      <Modal
        size={"md"}
        opened={modal.show}
        onClose={handleCloseModal}
        title={modal.title}
      >
        {modal.content}
      </Modal>
    </>
  );
};

export default KitsTab;
