import { DateTime } from "luxon";

function List({
  kit = {},
  kitHistory = [],
  hideFields = [],
}) {

  return (
    <>
      <table className="table flex-grow-1 d-flex flex-column ms-3" style={{fontFamily: 'Poppins', width: '97%'}}>
        <thead className="">
          <tr className="d-flex w-100 border-bottom gap-3">
            <th className="col-3 border-bottom-0 d-flex">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
              >
                Date{" "}
              </button>
            </th>
            <th className="col border-bottom-0 d-flex align-items-center">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
              >
                Action{" "}
              </button>
            </th>
            <th className="col-4 border-bottom-0 d-flex align-items-center">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
              >
                Message{" "}
              </button>
            </th>

            {!hideFields.includes('user') && 
              <th className="col border-bottom-0 d-flex align-items-center">
                <button
                  className="border-0 bg-white d-flex gap-2 align-items-center"
                >
                  User{" "}
                </button>
              </th>
            }

            <th className="col border-bottom-0 d-flex align-items-center">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
              >
                Duration{" "}
              </button>
            </th>
          </tr>
        </thead>
        <tbody className="flex-grow-1">
          {kitHistory.length === 0 ? (
            <tr>
              <td colSpan={4}>
                <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                  <h4 className="text-secondary">No Kit History Yet</h4>
                </div>
              </td>
            </tr>
          ) : (
            kitHistory.map((history, idx) => {
              let actionText, actionBg;
              switch (history?.action) {
                case 'UNASSIGN_PROPERTY_FROM_KIT':
                  actionText = 'Property Unassigned';
                  actionBg = 'bg-kit-grey';
                  break;
                case 'ASSIGN_PROPERTY_TO_KIT':
                  actionText = 'Property Assigned';
                  actionBg = 'bg-kit-green';
                  break;
                default:
                  actionText = 'Unknown Action'
                  actionBg = 'bg-warning';
              }

              const duration = history?.lastPropertyAssignmentDuration;
              return (
              <tr key={idx} className="d-flex mw-100 flex-grow-1 gap-3 py-2 border-bottom">
                <td className="col-3 border-bottom-0 d-flex">
                  <small>{history.datetime
                    ? DateTime.fromMillis(history.datetime).toLocaleString(DateTime.DATETIME_FULL)
                    : "Date Error"}</small>
                </td>

                <td className="col border-bottom-0 d-flex align-items-center">
                  <small className={`rounded-5 d-flex justify-content-center py-1 px-2 ${actionBg}`}>{actionText}</small>
                </td> 

                <td className="col-4 border-bottom-0 d-flex align-items-center">
                  {history?.message}
                </td>

               {!hideFields.includes('user') && 
                  <td className="col border-bottom-0 d-flex align-items-center">
                    {history?.actionByUser?.email}
                  </td>
               }

                <td className="col border-bottom-0 d-flex align-items-center">
                  {duration ?
                    `${duration?.days > 0 ? `${Math.ceil(duration?.days)} days` : ''} ${duration?.hours > 0 ? `${Math.ceil(duration?.hours)} hours` : ''} ${duration?.minutes > 0 ? `${Math.ceil(duration?.minutes)} minutes` : ''}` 
                    : '-'}
                </td>
              </tr>
            )})
          )}
        </tbody>
      </table>
    </>
  );
}

export default List;
