import React from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import styles from './styles.module.scss'
const ButtonDropdown = ({title, icon, items}) => {
    return (
      <DropdownButton
        as={ButtonGroup}
        title={
          <>
            {icon}
          </>
        }
        id="export-dropdown"
        className={styles.dropdown}
      >
      {items?.map((item, i) => 

        <Dropdown.Item key={i} eventKey={1} as="button" onClick={item.handleClick}>
          {item.name}
        </Dropdown.Item>

      )} 
      </DropdownButton>
    );
};

export default ButtonDropdown;