import './App.scss';

import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import AccountDetails from './pages/Admin/Accounts/Account-Details';
import AccountList from './pages/Admin/Accounts/AccountList';
import AccountForm from './pages/Admin/Accounts/AccountForm';
import HardwareDetails from './pages/Admin/Hardware/Hardware-Details';
import HardwareList from './pages/Admin/Hardware/HardwareList';
import HardwareForm from './pages/Admin/Hardware/HardwareForm';
import AddKit from './pages/Admin/Kit/Add-Kit';
import EditKit from './pages/Admin/Kit/Edit-kit';
import KitDetails from './pages/Admin/Kit/Kit-Details';
import KitList from './pages/Admin/Kit/KitList';
import AdminLogin from './pages/Admin/Sign-in';
import AddUser from './pages/Admin/User/Add-User';
import EditAdminUser from './pages/Admin/User/Edit-User';
import PasswordUser from './pages/Admin/User/User-Password';
import AdminUserDetails from './pages/Admin/User/User-Details';
import UserList from './pages/Admin/User/UserList';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Book from './pages/Book/Book';
import MeetingUnlock from './pages/Book/MeetingUnlock';
import Cameras from './pages/Cameras/Cameras';
import EditLeads from './pages/Leads/Edit-Lead';
import Leads from './pages/Leads/Leads';
import LeadsDetails from './pages/Leads/Leads-Details';
import PropertiesForm from './pages/Properties/PropertiesForm';
import PropertiesDetails from './pages/Properties/Properties-Details';
import Properties from './pages/Properties/Properties-List';
import Account from './pages/Account/Account';
import Showings from './pages/Showings/Showings';
import UserProfile from './pages/UserProfile/UserProfile';
import Subscription from './pages/Subscription/Subscription';
import Alert from './components/Alert';
import CancelBook from './pages/Book/CancelBook';
import Kits from './pages/Kits/Kits';
import KitHistory from './pages/KitHistory/KitHistory';


import { useGeneral } from './context/GeneralContext';
import { Auth } from 'aws-amplify';
import OrderNewKit from './pages/Orders/OrderNewKit';
import OrderSuccess from './pages/Orders/OrderSuccess';

import ShipmentDetail from './pages/Kits/tabs/ShipmentDetail';
import CreateShipment from './pages/Admin/Shipments/Create';
import EditShipment from './pages/Admin/Shipments/Edit';

import CreateDiscount from './pages/Admin/Discounts/Create';
import Orders from './pages/Admin/Orders/Orders';
import ShipmentsList from './pages/Admin/Shipments/List';
import CreateInvoice from './pages/Admin/Invoice/Create';
import InvoicesList from './pages/Admin/Invoice/List';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import DashboardPage from './pages/Dashboard';
import AddNewUser from './pages/Account/Tabs/users/AddNewUser';
import EditUser from './pages/Account/Tabs/users/EditUser';
import UserDetails from './pages/Account/Tabs/users/UserDetails';
import UpdatePassword from './pages/Account/Tabs/users/UpdatePassword';
import ShowInstructions from './pages/Book/InstructionsPage';
import SignUpAdmin from './pages/Admin/Sign-up';
import SignUp from './pages/Auth/SignUp';
import BookingPage from './pages/Book';
import Banner from './components/Banner';
import { ConfirmationPage } from './pages/Book/components/ConfirmationPage/ConfirmationPage';
import Approval from './pages/Auth/Approval';
import Installation from './pages/Orders/Installation';
import Removal from './pages/Orders/Removal';
import OrderCard from './pages/Kits/components/orders/OrderCard';

function App() {
  const { alertModal, setAlertModal } = useGeneral();
  

  return (
    <>
    <div className="App">
      {process.env.REACT_APP_URL ==="https://development.delet.com" &&  <Banner message="*****dev mode dev mode dev mode dev mode dev mode dev mode dev mode dev mode dev mode dev mode dev mode dev mode dev mode dev mode*****"/>}
      {process.env.REACT_APP_URL ==="https://staging.delet.com" &&  <Banner message="*****staging mode staging mode staging mode staging mode staging mode staging mode staging mode staging mode staging mode staging mode staging mode*****"/>}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to="/login" />} />
          <Route path='/login' element={<Login />} />
          <Route path='/admin/login' element={<AdminLogin />} />
          <Route path='/admin/signup' element={<SignUpAdmin />} />

          <Route path='/property/list' element={<RequireAuth><Properties /></RequireAuth>} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />

          <Route path='/account/user/create' element={<RequireAuth><AddNewUser /></RequireAuth>} />
          <Route path='/account/user/edit-user/:id' element={<RequireAuth><EditUser /></RequireAuth>} />
          <Route path='/account/user/details/:id' element={<RequireAuth ><UserDetails /></RequireAuth>} />
          <Route path='/account/user/password/:id' element={<RequireAuth ><UpdatePassword /></RequireAuth>} />

          <Route path='/admin/user/list' element={<RequireSuperAdminAuth redirectTo='/admin/login'><UserList /></RequireSuperAdminAuth>} />
          <Route path='/admin/user/create' element={<RequireSuperAdminAuth redirectTo='/admin/login'><AddUser /></RequireSuperAdminAuth>} />
          <Route path='/admin/user/edit-user/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><EditAdminUser /></RequireSuperAdminAuth>} />
          <Route path='/admin/user/password/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><PasswordUser /></RequireSuperAdminAuth>} />
          <Route path='/admin/user/details/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><AdminUserDetails /></RequireSuperAdminAuth>} />
          <Route path='/admin/user/kit/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><KitDetails /></RequireSuperAdminAuth>} />
          <Route path='/admin/user/edit-kit/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><EditKit /></RequireSuperAdminAuth>} />

          <Route path='/admin/account/list' element={<RequireSuperAdminAuth redirectTo='/admin/login'><AccountList /></RequireSuperAdminAuth>} />
          <Route path='/admin/account/details/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><AccountDetails /></RequireSuperAdminAuth>} />
          <Route path='/admin/account/edit/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><AccountForm /></RequireSuperAdminAuth>} />
          <Route path='/admin/account/create' element={<RequireSuperAdminAuth redirectTo='/admin/login'><AccountForm /></RequireSuperAdminAuth>} />

          <Route path='/admin/hardware/list' element={<RequireSuperAdminAuth redirectTo='/admin/login'><HardwareList /></RequireSuperAdminAuth>} />
          <Route path='/admin/hardware/details/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><HardwareDetails /></RequireSuperAdminAuth>} />
          <Route path='/admin/hardware/edit/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><HardwareForm /></RequireSuperAdminAuth>} />
          <Route path='/admin/hardware/create' element={<RequireSuperAdminAuth redirectTo='/admin/login'><HardwareForm /></RequireSuperAdminAuth>} />

          <Route path='/admin/shipment/create' element={<RequireSuperAdminAuth redirectTo='/admin/login'><CreateShipment /></RequireSuperAdminAuth>} />
          <Route path='/admin/shipment/edit/:id' element={<RequireSuperAdminAuth redirectTo='/admin/login'><EditShipment /></RequireSuperAdminAuth>} />
          <Route path='/admin/shipment/list' element={<RequireSuperAdminAuth redirectTo='/admin/login'><ShipmentsList /></RequireSuperAdminAuth>} />

          <Route path='/admin/invoices/create' element={<RequireSuperAdminAuth redirectTo='/admin/login'><CreateInvoice /></RequireSuperAdminAuth>} />
          <Route path='/admin/invoices' element={<RequireSuperAdminAuth redirectTo='/admin/login'><InvoicesList /></RequireSuperAdminAuth>} />

          <Route path='/admin/discount/create' element={<RequireSuperAdminAuth redirectTo='/admin/login'><CreateDiscount /></RequireSuperAdminAuth>} />
          <Route path='/admin/kit/list' element={<RequireSuperAdminAuth redirectTo='/admin/login'><KitList /></RequireSuperAdminAuth>} />
          <Route path='/admin/orders' element={<RequireSuperAdminAuth redirectTo='/admin/login'><Orders /></RequireSuperAdminAuth>} />
          <Route path='/admin/kit/create' element={<RequireSuperAdminAuth redirectTo='/admin/login'><AddKit /></RequireSuperAdminAuth>} />

          <Route path='/user-profile' element={<RequireAuth><UserProfile /></RequireAuth>} />

          <Route path='/register' element={<Register />} />

          <Route path='/signup' element={<SignUp />} />
          <Route path='/approval' element={<Approval />} />

          <Route path='/property/list' element={<RequireAuth><Properties /></RequireAuth>} />
          <Route path='/property/add' element={<RequireAuth><PropertiesForm /></RequireAuth>} />
          <Route path='/property/edit/:id' element={<RequireAuth><PropertiesForm /></RequireAuth>} />
          <Route path='/property/:id' element={<RequireAuth><PropertiesDetails /></RequireAuth>} />
          <Route path='/property/:id/:tab' element={<RequireAuth><PropertiesDetails /></RequireAuth>} />

          <Route path='/leads' element={<RequireAuth><Leads /></RequireAuth>} />
          <Route path='/leads-details/:id' element={<RequireAuth><LeadsDetails /></RequireAuth>} />
          <Route path='/edit-leads/:id' element={<RequireAuth><EditLeads /></RequireAuth>} />

          <Route path='/showings' element={<RequireAuth><Showings /></RequireAuth>} />

          {/* <Route path='/cameras' element={<RequireAuth><Cameras /></RequireAuth>} /> */}
          <Route path='/account' element={<RequireAuth><Account /></RequireAuth>} />
          <Route path='/account/:tab' element={<RequireAuth><Account /></RequireAuth>} />

          <Route path='/book/cancel' element={<CancelBook />} />
          {/* <Route path='/book/:propertyId' element={<Book />} /> */}
          <Route path='/book/:state/:city/:address/:unit?' element={<Book />} />

          <Route path='/book/list/:agentId' element={<BookingPage />} />
          <Route path='/book/property/:propertyId/:agentId' element={<BookingPage />} />
          <Route path='/book/building/:buildingId/:agentId' element={<BookingPage />} />
          <Route path='/book/reschedule' element={<BookingPage reschedule/>} />
          <Route path='/book/confirmation' element={<ConfirmationPage />} />
          <Route path='/book/confirmation/:meetingId' element={<ConfirmationPage />} />

          <Route path='/tour-instructions' element={<ShowInstructions />} />

          <Route path='/booking/unlock/:meetingid/:lockKey/' element={<MeetingUnlock />} />

          <Route path='/subscription' element={<RequireAuth><Subscription /></RequireAuth>} />

          <Route path='/kits' element={<RequireAuth><Kits /></RequireAuth>} />
          <Route path='/kits/history/:id' element={<RequireAuth><KitHistory /></RequireAuth>} />
          <Route path='/kits/:tab' element={<RequireAuth><Kits /></RequireAuth>} />

          <Route path='/shipment/:id' element={<RequireAuth><ShipmentDetail /></RequireAuth>} />

          <Route path='/order' element={<RequireAuth><OrderNewKit /></RequireAuth>} />
          <Route path='/order/success' element={<RequireAuth><OrderSuccess /></RequireAuth>} />
          <Route path='/order/summary/:id' element={<RequireAuth><OrderCard/></RequireAuth>} />
          <Route path= '/order/install/:id' element={<RequireAuth><Installation /></RequireAuth>} />
          <Route path= '/order/remove/:id' element={<RequireAuth><Removal /></RequireAuth>} />

          <Route path='/dashboard' element={<RequireAuth><DashboardPage /></RequireAuth>} />

        </Routes >
        {
          alertModal.show
          &&
          <Alert
            show={alertModal.show}
            title={alertModal.title}
            content={alertModal.content}
            setShow={() => setAlertModal({ ...alertModal, show: false })}
          />
        }
      </BrowserRouter >
    </div >
    </>
  );
}

export default App;


async function fetchUserStatus() {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_NODE_API_URL}/api/users/active-status`, {
    headers: {
      Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
    },
  });

  if (!response.ok) {
    throw new Error('User status check failed');
  }

  return response.json();
}

function RequireAuth({ children, redirectTo = "/approval" }) {
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['userStatus'],
    queryFn: () => fetchUserStatus(),
    staleTime: 60000, 
    retry: false, 
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isError) {
      navigate(redirectTo);
    } else if (!isLoading && data) {
      if (!data.active) {
        navigate(redirectTo);
      }
    }
  }, [isLoading, isError, data, navigate, redirectTo]);

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  return data.active ? children : null;
}




function RequireSuperAdminAuth({ children, redirectTo = "/" }) {
  const navigate = useNavigate();
  const [isSuperAdmin, setIsSuperAdmin] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((response) => {
        if (response.signInUserSession.idToken.payload["cognito:groups"].includes("superadmin")) {
          setIsSuperAdmin(true)
        } else {
          navigate(redirectTo)
        }
      })
      .catch((err) => {
        console.log("Error while login", err);
        navigate(redirectTo);
      });
  }, [navigate]);


  return isSuperAdmin ? children : null;
}