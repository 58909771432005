import axios from "axios";
const { Auth } = require("aws-amplify");

export const createPassCode = async (keypadId, passCode) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/keypad/${keypadId}/pass-code`;

  try {
    const token = await Auth.currentSession();
    const res = await axios.post(url, passCode, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
    });

    const { statusCode, body, message } = res.data;
    if (statusCode === 100) {
      return { body, message };
    } else {
      throw new Error(message);
    }

  } catch (error) {
    if (error.response) {
      const { statusCode, message } = error.response.data.response;
      throw new Error(`Error: ${message}, StatusCode: ${statusCode}`);
    } else {
      throw new Error(error.message);
    }
  }
};

export const createNextTwoMonthsCodes = async (keypadId) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/keypad/create-daily-code/${keypadId}`;

  try {
    const token = await Auth.currentSession();
    const res = await axios.post(url, {}, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
    });

    const { data } = res;
    if (res.status === 200) {
      return data;
    } else {
      throw new Error(data.error);
    }

  } catch (error) {
    // If the error is from axios
    if (error.response) {
      const { statusCode, message } = error.response.data.response;
      throw new Error(`Error: ${message}, StatusCode: ${statusCode}`);
    } else {
      // If the error is not from axios
      throw new Error(error.message);
    }
  }
};

export const getCodeHistory = async ({keypadId, filter}) => {
  if (!keypadId) throw new Error("No keypad found")
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/keypad/${keypadId}/pass-code?filter=${filter}`;

  try {
    const token = await Auth.currentSession();
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
    });

    return res.data
  } catch (error) {
    if (error.response) {
      const { statusCode, message } = error.response.data.response;
      throw new Error(`Error: ${message}, StatusCode: ${statusCode}`);
    } else {
      throw new Error(error.message);
    }
  }
};

export const deletePassCodes = async (idList, keypadId) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/keypad/${keypadId}/pass-code`;
  
  try {
    const token = await Auth.currentSession();
    const res = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
      data: { idList },
    });

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data || "Something went wrong");
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { deletedCodes, message } = error.response.data;

      const customError = new Error(message || "An error occurred");
      customError.deletedCodes = deletedCodes || [];
      customError.status = error.response.status;
      
      throw customError;
    } else {
      throw new Error(error.message || "Something went wrong");
    }
  }
};