import Sidebar from "../../components/SideBar";
import { useParams } from "react-router-dom";
import ListWrapper from "./components/ListWrapper";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";


const Kits = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const Header = ({ kit } = {}) => {
    return <header className="d-flex justify-content-between align-items-center" style={{backgroundColor: '#EAEAEB', height: '126px'}}>
      <h4 className="fs-3 fw-bold pt-0 ps-5">
        <IoIosArrowBack onClick={() => navigate(`/kits`) } /> {" "} {kit?.name &&  `${kit?.name}`}
      </h4>
    </header>
  }

  return (
    <Sidebar>
      <section className="min-h-100vh d-flex flex-column gap-2">
        <ListWrapper id={id} header={Header} hideFields={['user']} />
      </section>
    </Sidebar>
  );
};
export default Kits;
