import "../Leads.scss";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Container } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { DateRangeSelect } from "../../../components/Selects/DateRangeSelect";
import { MultiselectSearch } from "../../../components/Selects/MultiselectSearch";
import { useQuery } from "@tanstack/react-query";
import { getAllProperties } from "../../../api/properties";

export const FiltersModal = ({
  show,
  handleClose,
  handleApplyFilters,
}) => {

  const [selectedProperties, setSelectedProperties] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const handleDateRangeChange = (value) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  const {
    data: properties,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["all-my-properties"],
    queryFn: () => getAllProperties({ fields: "address shortAddress kit" }),
  });  

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="modal-dialog"
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center modal-title p-1 ">
          <CiFilter size="22px" /> <span>Filter</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Form.Label htmlFor="dateRangeSelect">Date</Form.Label>
              <DateRangeSelect
                startDate={startDate}
                endDate={endDate}
                handleDateRangeChange={handleDateRangeChange}
              />
            </Col>
            <Col>
              <Form.Label htmlFor="propertySelect">Property</Form.Label>
              
              <MultiselectSearch
                data={properties}
                selected={selectedProperties}
                handleSelectedProperties={(properties) => setSelectedProperties(properties)}
                disable={isLoading}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleApplyFilters({selectedProperties, startDate, endDate})}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
