import React, { useState } from "react";
import moment from "moment";

import sortByIcon from "../../../assests/images/sortBy.svg";
import {
  FaSortAlphaDown,
  FaSortAlphaDownAlt,
  FaSortNumericDown,
  FaSortNumericDownAlt,
} from "react-icons/fa";
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import AssignProperty from "./AssignProperty";
import { statusClassMap } from "../../../helper/kits";
import StatusText from "./StatusText";
import { RiPencilLine } from "react-icons/ri";
import { design } from "../../../constants";
import { Link, useNavigate } from "react-router-dom";

function KitsTable({
  kits,
  setSelectedKits,
  selectedKits,
  setModal,
  closeModal,
}) {
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [sortBy, setSortBy] = useState({});

  const [page, setPage] = useState(0);
  const kitsPerPage = 11;

  const startIndex = page * kitsPerPage;
  const endIndex = startIndex + kitsPerPage;

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      const allKits = kits.map((kit) => kit._id);
      setSelectedKits(allKits);
    } else {
      setSelectedKits([]);
    }
  };

  const handleSelectKit = (checked, kit) => {
    setSelectAll(false);

    setSelectedKits((prev) => {
      const newSelection = [...prev];
      if (checked) {
        newSelection.push(kit._id);
      } else {
        const index = prev.indexOf(kit._id);
        if (index > -1) newSelection.splice(index, 1);
      }
      return newSelection;
    });
  };

  const handleSort = (type) => {
    setSortBy({ [type]: !sortBy[type] });
  };

  const showAssignProperty = (kit) => {
    setModal({
      show: true,
      content: <AssignProperty kit={kit} closeModal={closeModal} />,
      title: "",
    });
  };

  const sortedKits = kits.sort((a, b) => {
    const [field, order] = Object.entries(sortBy)[0] || [];
    if (!field) return 0;

    if (field === "name" || field === "property" || field === "status") {
      const fieldA =
        field === "property"
          ? a.property
            ? a.property.shortAddress
            : ""
          : a[field];
      const fieldB =
        field === "property"
          ? b.property
            ? b.property.shortAddress
            : ""
          : b[field];
      if (fieldA < fieldB) return order ? -1 : 1;
      if (fieldA > fieldB) return order ? 1 : -1;
      return 0;
    }

    if (field === "date") {
      if (!a.assignedDate && b.assignedDate) return 1;
      if (a.assignedDate && !b.assignedDate) return -1;

      if (a.assignedDate < b.assignedDate) return order ? -1 : 1;
      if (a.assignedDate > b.assignedDate) return order ? 1 : -1;
      return 0;
    }

    return 0;
  });

  const totalPages = Math.ceil(sortedKits.length / kitsPerPage);

  const currentPageKits = sortedKits.slice(startIndex, endIndex);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <>
      <table
        class="table ms-3 flex-grow-1 d-flex flex-column"
        style={{ fontFamily: "Poppins", width: "97%" }}
      >
        <thead className="">
          <tr className="d-flex mw-100 border-bottom gap-2">
            <th className="col-2 col-xxl-3 border-bottom-0 d-flex gap-3">
              <input
                class="form-check-input"
                type="checkbox"
                checked={selectedKits.length === kits.length}
                id="flexCheckDefault"
                onChange={handleSelectAll}
              />
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
                onClick={() => handleSort("name")}
              >
                Kit Number{" "}
                {sortBy.name === undefined ? (
                  <MdKeyboardArrowDown size={30} />
                ) : sortBy.name ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </th>
            <th className="col border-bottom-0 d-flex align-items-center d-none">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
                onClick={() => handleSort("status")}
              >
                Status{" "}
                {sortBy.status === undefined ? (
                  <MdKeyboardArrowDown size={30} />
                ) : sortBy.status ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </th>
            <th className="col-5 col-xxl-4 border-bottom-0 d-flex align-items-center">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
                onClick={() => handleSort("property")}
              >
                Property{" "}
                {sortBy.property === undefined ? (
                  <MdKeyboardArrowDown size={30} />
                ) : sortBy.property ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </th>
            <th className="col-3 border-bottom-0 d-flex align-items-center ms-5">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
                onClick={() => handleSort("date")}
              >
                Activation Date{" "}
                {sortBy.date === undefined ? (
                  <MdKeyboardArrowDown size={30} />
                ) : sortBy.date ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </th>

            <th className="col-2 border-bottom-0 d-flex align-items-center">
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody className="flex-grow-1">
          {currentPageKits.length === 0 ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <h4 className="text-secondary">...No kits to show</h4>
            </div>
          ) : (
            currentPageKits.map((kit) => (
              <tr className="d-flex mw-100 flex-grow-1 gap-2 py-2 border-bottom">
                <td className="col-2 col-xxl-3 border-bottom-0 d-flex gap-3 text-nowrap">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={selectAll || selectedKits.includes(kit._id)}
                    id="flexCheckDefault"
                    onChange={(e) => handleSelectKit(e.target.checked, kit)}
                  />{" "}
                  {kit.name}
                </td>

                <td className="col border-bottom-0 d-none">
                  <StatusText status={statusClassMap[kit.state]} />
                </td>

                <td className="col-5 col-xxl-4 border-bottom-0 ">
                  {kit.property ? (
                    <button
                      className="border-0 bg-white d-flex align-items-center justify-content-between w-100 text-start"
                      onClick={() => showAssignProperty(kit)}
                    >
                      <span className="text-nowrap text-truncate">
                        {kit.property.address}
                      </span>{" "}
                      <RiPencilLine
                        color={design.deleteMainColor}
                        size={25}
                        style={{ marginRight: "30px" }}
                      />
                    </button>
                  ) : (
                    <button
                      className="border-0 bg-white d-flex gap-1 align-items-center text-delet-blue"
                      onClick={() => showAssignProperty(kit)}
                    >
                      <div className="sign-icon">
                        <span className="">+</span>
                      </div>
                      Assign property
                    </button>
                  )}
                </td>

                <td className="col-3 col-xxl-3 border-bottom-0 ms-5">
                  {kit.assignedDate
                    ? moment(kit.assignedDate).format("MMMM Do, YYYY")
                    : "Not active"}
                </td>

                <td className="col-2 border-bottom-0">
                  <Link
                    className="btn btn-primary me-5 bg-delet border-0"
                    to={`/kits/history/${kit._id}`}
                  >
                    Kit History
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <ul class="pagination mb-0 mx-auto pb-3">
        <li class="page-item">
          <button
            class="page-link"
            onClick={() => setPage(page - 1)}
            disabled={page === 0}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {pageNumbers.map((pageNumber) => (
          <li class="page-item">
            <button
              class={`page-link ${pageNumber - 1 === page && "fw-bold"}`}
              onClick={() => setPage(pageNumber - 1)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        <li class="page-item">
          <button
            class="page-link"
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages - 1}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </>
  );
}

export default KitsTable;
