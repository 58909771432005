import React, { useState } from "react";
import { Center, Text, Loader, Button } from "@mantine/core";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { getOrders } from "../../../api/orders";
import OrderCard from "../components/orders/OrderCard";
import UnstyledButton from "../../../components/UnstyledButton";
import StatusText from "../components/StatusText";
import { orderStatusDic } from "../../../helper/kits";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["orders"],
    queryFn: () => getOrders(),
  });

  const rows = data?.map((order) => (
    <div className="d-flex border-bottom py-2" key={order?._id}>
      <div className="col-4 d-flex align-items-center">
        <input type="checkbox" className="me-2 d-none" />
        <span className="text-truncate">{order?._id}</span>
      </div>
      <div className="col-4 text-start">
        {format(order?.createdAt, "LLLL do, yyyy")}
      </div>
      <div className="col-4">
        <Button
          className="btn btn-primary"
          // onClick={() => setSelectedOrder(order)}
          onClick={() => navigate(`/order/summary/${order?._id}`, { state: { order } })} 
          style={{backgroundColor: '#5271FF', border: 'none'}}
        >
          View Order
        </Button>
      </div>
    </div>
  ));
  

  return isLoading ? (
    <Center m={50}>
      <Loader color="blue" />
    </Center>
  ) : isError ? (
    <p>Error getting orders, please try again</p>
  ) : (
    <section className="d-flex flex-grow-1">
      <div className="col-12 d-flex flex-column gap-1 pt-4 mx-4" style={{width: '97%'}}>
        <div className="d-flex border-bottom py-1">
          <strong className="col-4">ID</strong>
          <strong className="col-4">Date</strong>
          <strong className="col-4">Action</strong>
        </div>

        <div className="d-flex flex-column orders-list-container">
          {!data?.length ? (
            <Center m={50}>
              <Text>You haven't made any orders yet</Text>
            </Center>
          ) :
            rows
          }
        </div>
      </div>
      
      {/* <div className="bg-secondary p-4 bg-opacity-10 col-7">
        {selectedOrder ? <OrderCard order={selectedOrder} /> : null}
      </div> */}
    </section>
  );
};
export default Orders;
